import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";
import ReactSlick from "react-slick";

import { Query } from "@graphql-types";
import { Carousel, Image } from "@sub";
import { assets, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, H2 } from "@util/standard";

const Wrapper = styled(Container)`
  width: 90%;
  margin: auto;
  flex-direction: row;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const CarouselWrapper = styled(Container)`
  margin: 0;
  width: 70%;
  .slick-list {
    width: 75vw;

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: 95vw;
    }
  }
`;

const ImageContainer = styled(Container)<{}>`
  width: 100%;
  margin: 0;
  position: relative;
  height: 600px;
  overflow: hidden;

  :focus {
    outline: 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 400px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 250px;
  }
`;

const Img = styled.img`
  cursor: pointer;
  margin-right: 35px;
  width: auto;
  height: 23px;
`;

const MottoContainer = styled(Container)`
  flex-direction: column;
  margin: auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ArrowContainer = styled(Container)`
  width: auto;
  margin: 0;
  margin-top: 40px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    align-items: flex-end;
    margin: 20px 0;
  }
`;

const TeamSlider = () => {
  const sliderRef = useRef<ReactSlick>(null);

  const { sanityAbout }: Query = useStaticQuery(graphql`
    {
      sanityAbout {
        teamMotto
        teamImages {
          asset {
            fluid(maxWidth: 2000) {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
            _id
          }
        }
      }
    }
  `);

  const TeamMotto = () => {
    const handlePrevSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    };

    const handleNextSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    };

    return (
      <MottoContainer>
        <H2>{sanityAbout?.teamMotto}</H2>
        <ArrowContainer>
          <Img
            src={assets.leftArrowBlue}
            onClick={handlePrevSlide}
            alt="leftArrow"
          />
          <Img
            src={assets.rightArrowBlue}
            onClick={handleNextSlide}
            alt="rightArrow"
          />
        </ArrowContainer>
      </MottoContainer>
    );
  };

  return (
    <Wrapper overflow="hidden" maxWidth="90%" margin="auto">
      <TeamMotto />

      <CarouselWrapper>
        <Carousel hideArrow slidesToShow={1.15} sliderRef={sliderRef}>
          {sanityAbout?.teamImages?.map((image) => {
            return (
              <ImageContainer key={image?.asset?._id}>
                <Image
                  isBackground
                  backgroundStyle={{
                    width: "100%",
                    height: "100%",
                    margin: "0 10px",
                  }}
                  fluid={image?.asset?.fluid as FluidObject}
                  data={image}
                  objectFit="contain"
                />
              </ImageContainer>
            );
          })}
        </Carousel>
      </CarouselWrapper>
    </Wrapper>
  );
};

export default TeamSlider;
