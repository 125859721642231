import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Container, H1, H3, H2, P, A } from "@util/standard";
import { Query } from "@graphql-types";
import {
  colors,
  pages,
  MOBILE_BREAKPOINT,
  DEFAULT_OFFSET,
  MOBILE_OFFSET,
  animationType,
} from "@util/constants";
import { Modal, BlocksContent, Button, AnimationContainer } from "@sub";
import TeamSlider from "@components/people/teamSlider";

const Wrapper = styled(Container)`
  margin: ${DEFAULT_OFFSET}px auto 80px auto;
  width: 100%;
  flex-direction: column;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 30px auto;
  }
`;

const HeroContainer = styled(Container)`
  flex-direction: column;

  width: 70%;
  margin-bottom: 80px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${MOBILE_OFFSET}px auto 30px auto;
    width: 80%;
  }
`;

const CareerContainer = styled(Container)`
  width: 70%;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`;

const GetInTouchContainer = styled(Container)`
  margin: ${DEFAULT_OFFSET}px auto;
  width: 100%;
  background-color: ${colors.flightBlue};
  flex-direction: column;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${MOBILE_OFFSET}px auto;
  }
`;

const TextContainer = styled(Container)`
  width: 70%;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`;

const ListItemWrapper = styled(Container)`
  width: auto;
  margin: 0;
  flex-direction: column;
  cursor: pointer;
`;

const ListItem = styled(Container)`
  width: auto;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid ${colors.flightBlue};
  justify-content: space-between;
  cursor: pointer;
`;

const ModalContentContainer = styled(Container)`
  margin: 50px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 30px auto;
  }
`;

const Careers = () => {
  const { sanityCareers }: Query = useStaticQuery(graphql`
    {
      sanityCareers {
        heroTitle
        heroSummary {
          _rawColumnContent
        }
        positions {
          _key
          title
          published
          description {
            _rawColumnContent
          }
        }
        contactTitle
        contactText
        contactEmail
      }
    }
  `);

  if (sanityCareers == null) {
    return null;
  }

  const hasOpenRoles =
    sanityCareers.positions && sanityCareers.positions.length > 0;

  const GetInTouch = () => {
    return (
      <GetInTouchContainer>
        <TextContainer margin="80px auto" flexDirection="column">
          <H2 noMargin color="white">
            {sanityCareers.contactTitle}
          </H2>
          <P color="white">{sanityCareers.contactText}</P>
          <Button
            theme="whiteInverse"
            text="Get in touch"
            margin="20px 0 0 0"
            width="fit-content"
            onClick={() => {
              window.location = "mailto:" + sanityCareers.contactEmail;
            }}
          />
        </TextContainer>
      </GetInTouchContainer>
    );
  };

  return (
    <Wrapper>
      <HeroContainer flexDirection="column">
        <H1 margin="0px">{sanityCareers.heroTitle}.</H1>
        <BlocksContent blocks={sanityCareers.heroSummary?._rawColumnContent} />
      </HeroContainer>

      {hasOpenRoles ? (
        <CareerContainer flexDirection="column">
          <H2 margin="0px 0px 10px 0px">Current open roles.</H2>
          <ListItemWrapper>
            {sanityCareers.positions?.map((position) => {
              if (position == null || !position.published) {
                return null;
              }

              const [modalVisible, setModalVisible] = useState(false);

              return (
                <div key={position._key}>
                  <ListItem onClick={() => setModalVisible(true)}>
                    <P noMargin width="80%">
                      {position.title}
                    </P>
                    <P noMargin bold>{`More >`}</P>
                  </ListItem>
                  <Modal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                  >
                    <ModalContentContainer>
                      <BlocksContent
                        blocks={position.description?._rawColumnContent}
                      />
                    </ModalContentContainer>
                  </Modal>
                </div>
              );
            })}
          </ListItemWrapper>
        </CareerContainer>
      ) : (
        <CareerContainer>
          <H3>
            We don't currently have any open roles but if you would like to be
            considered for any future openings please do send your resume to
            <A href="mailto:careers@flightdigital.co.nz">
              {" "}
              careers@flightdigital.co.nz
            </A>
          </H3>
        </CareerContainer>
      )}

      <GetInTouch />
      <AnimationContainer animationType={animationType.slideInBottom}>
        <Container>
          <TeamSlider />
        </Container>
      </AnimationContainer>
    </Wrapper>
  );
};

export default Careers;
