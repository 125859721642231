import React from "react";
import { graphql, PageProps } from "gatsby";

import CareersContent from "@components/careers";
import SEO from "@components/shared/seo";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  data: Query;
}

export default function Careers({ data }: Props) {
  const { sanityCareers } = data;

  return (
    <div>
      <SEO seoData={sanityCareers?.seo} slug="careers" />
      <CareersContent />
    </div>
  );
}

export const query = graphql`
  query CareersQuery {
    sanityCareers {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        jsonLD {
          _key
          _type
          language
          filename
          code
        }
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
